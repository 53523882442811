<template>
	<div class="searchCon">
		<div class="searchBox flex align_items_c">
			<img
				src="https://zy.metaera.media/assets/images/search/1176.png"
				alt=""
				@click="search"
				class="pointer"
			/>
			<input type="text" placeholder="请输入关键词" v-model="keywords" />
		</div>
		<div class="historySearch">
			<div class="historySearch_l flex justify_content_sb align_items_c">
				<span>历史搜索</span>
				<span class="pointer" @click="delHistory('', 2, -1)">清除历史</span>
			</div>
			<div class="historySearch_2 flex flex_wrap">
				<div
					class="historySearch_2_item flex align_items_c pointer"
					v-for="(item, index) in searchRecords"
					:key="index"
				>
					<span
						@click="
							$router.push({ path: '/gw_search', query: { key: item.name } })
						"
						>{{ item.name }}</span
					>
					<img
						src="https://zy.metaera.media/assets/images/search/1327.png"
						alt=""
						class="pointer"
						@click="delHistory(item.id, 1, index)"
					/>
				</div>
			</div>
		</div>
		<div class="rsTitle">MetaEra 热搜</div>
		<div class="flex align_items_c justify_content_sb">
			<div class="rsList_search subList">
				<div
					class="rsItem flex align_items_c pointer"
					v-for="(item, index) in hotAllSearchList"
					:key="index"
					@click="
						$router.push({
							path: '/kuaixun_wz',
							query: { id: item.id },
						})
					"
				>
					<div class="ellipsis_one_line"></div>
					<div class="ellipsis_one">{{
						localLanguage == 2
							? item.title
								? item.title
								: item.title
							: item.title
					}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { hotSearch, getSearchRecords, delSearchRecords } from "@/api/home";
import { getToken } from "@/utils/auth";
import { eventBus } from "../../components/pc/leftTab";
export default {
	name: "",
	data() {
		return {
			keywords: "",
			hotAllSearchList: [],
			hotSearchList: [],
			hotSearchList2: [],
			searchRecords: [],
			localLanguage: 1,
		};
	},
	created() {
		this.keywords = this.$route.query.keyword;
		this.hotSearch();
		this.getSearchRecords();
		if (this.keywords != null && this.keywords.length > 0) {
			this.search();
		}
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {
		change() {
			// console.log(this.keywords);
		},
		hotSearch() {
			hotSearch({})
				.then((res) => {
					if (res.code == 200) {
						this.hotAllSearchList = res.data;
						this.hotSearchList = res.data.slice(0, 5);
						this.hotSearchList2 = res.data.slice(-5);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getSearchRecords() {
			const hasToken = getToken();
			if (hasToken) {
				getSearchRecords({})
					.then((res) => {
						if (res.code == 200) {
							this.searchRecords = res.data;
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				let _obj = JSON.parse(sessionStorage.getItem("searchRecords"));
				this.searchRecords = [];
				if (_obj) {
					for (let i = 0; i < _obj.length; i++) {
						if (_obj[i] != null) {
							if (_obj[i].name == null || _obj[i].name == "") {
							} else {
								this.searchRecords.push(_obj[i]);
							}
						}
					}
				}
			}
		},
		delHistory(id, type, index) {
			const hasToken = getToken();
			if (hasToken) {
				delSearchRecords({ id: id, type: type })
					.then((res) => {
						if (res.code == 200) {
							this.getSearchRecords();
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				let _obj = this.searchRecords;
				if (type == 2) {
					_obj = [];
				} else {
					if (_obj != null) {
						this.$set(_obj, index, 1);
					}
				}
				this.searchRecords = _obj;
				sessionStorage.setItem(
					"searchRecords",
					JSON.stringify(this.searchRecords)
				);
				this.getSearchRecords();
			}
		},
		search() {
			this.$router.push({ path: "/gw_search", query: { key: this.keywords } });
		},
	},
};
</script>
<style scoped>
.searchCon {
	width: 770px;
	margin: 0 auto;
	margin-top: 88px;
	padding-bottom: 200px;
}

.searchBox {
	width: 100%;
	height: 54px;
	background: #ffffff;
	box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
	border-radius: 33px 33px 33px 33px;
	opacity: 1;
}

.searchBox img {
	width: 22px;
	height: 22px;
	margin-left: 20px;
	margin-right: 10px;
}
.searchBox input {
	width: 80%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.rsTitle {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 52px;

	font-size: 20px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}
.historySearch {
	margin-top: 52px;
}
.historySearch_l span:nth-child(1) {
	font-size: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}
.historySearch_l span:nth-child(2) {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a1a1aa;
}
.historySearch_2 {
	margin-top: 8px;
	margin-left: -38px;
}
.historySearch_2_item {
	padding: 10px;
	margin-top: 18px;
	margin-left: 38px;
	background: #ededed;
	border-radius: 4px;
}
.historySearch_2_item span {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a1a1aa;
}
.historySearch_2_item img {
	width: 14px;
	height: 14px;
	margin-left: 8px;
}
.allList {
	display: none;
}
.subList {
	display: block;
}
.rsList_search {
	background: #ffffff;
	margin-top: 20px;
	margin-right: 20px;
	border-radius: 20px;
	padding: 20px;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}
.ellipsis_one {
	width: 360px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	color: #000000;
}

.ellipsis_one_line {
	width: 2px;
	height: 10px;
	border-radius: 153px;
	opacity: 1;
	/* 主色（一级颜色） */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	margin-right: 10px;
}
@media screen and (max-width: 950px) {
	.rsItem {
		margin-top: 8px;
	}
	.rsList_search {
		background: #ffffff;
		margin-top: 0;
	}
	.allList {
		display: block;
	}
	.subList {
		display: none;
	}
	.searchCon {
		width: 90%;
		margin: 0 auto;
		padding-top: calc(100vw * 10 / 375);
	}
	.searchBox {
		width: 100%;
		height: calc(100vw * 40 / 375);
		margin-top: calc(100vw * 10 / 375);
		background: #ffffff;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
		border-radius: calc(100vw * 20 / 375);
		opacity: 1;
	}

	.searchBox input {
		width: 80%;
		height: 90%;
		padding-left: calc(100vw * 20 / 375);
		border: none;
		outline: none;
		border-radius: 33px 0px 0px 33px;
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #000000;
	}

	.searchBox img {
		width: calc(100vw * 16 / 375);
		height: calc(100vw * 16 / 375);
		margin-right: calc(100vw * 20 / 375);
	}

	.rsTitle {
		font-size: calc(100vw * 16 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #000000;
		margin-top: 52px;
	}
	.historySearch {
		margin-top: calc(100vw * 20 / 375);
	}
	.historySearch_l span:nth-child(1) {
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #000000;
	}
	.historySearch_l span:nth-child(2) {
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #a1a1aa;
	}
	.historySearch_2 {
		margin-top: 8px;
		margin-left: -38px;
	}
	.historySearch_2_item {
		margin-top: 18px;
		margin-left: 38px;
	}
	.historySearch_2_item span {
		font-size: calc(100vw * 14 / 375);
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #a1a1aa;
	}
	.historySearch_2_item img {
		width: 14px;
		height: 14px;
		margin-left: 8px;
	}

	.ellipsis_one {
		margin-top: calc(100vw * 14 / 375);
		margin-left: calc(100vw * 6 / 375);
		font-size: calc(100vw * 14 / 375);
		width: 90%;
	}
	.rsList_search,
	.rsItem {
		background: #ffffff !important;
		width: 100%;
	}
	.xhBox {
		margin-top: calc(100vw * 14 / 375);
		height: calc(100vw * 14 / 375);
		width: calc(100vw * 14 / 375);
		font-size: calc(100vw * 10 / 375);
		justify-content: center;
		display: flex;
		align-items: center;
	}
}
</style>
<style>
@media screen and (max-width: 950px) {
}
</style>
